<template>
  <b-container fluid>
    <b-overlay :show="loadingForm">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
              <b-row v-if="isdue">
                <b-col lg="12" sm="12">
                  <h5 class="mb-2" style="font-weight:bold"><i class="ri-list-check"></i> {{ $t('externalUserIrrigation.security_money') + ' ' + $t('globalTrans.list') }}</h5>
                    <table class="table table-bordered table-sm">
                      <tr>
                        <th>{{ $t('pump_install.participation_category') }}</th>
                        <th>{{ $t('pump_install.discharge_cusec') }}</th>
                        <th>{{ $t('pump_install.amount') }}</th>
                        <th>{{ $t('externalUserIrrigation.payment_type') }}</th>
                        <th>{{ $t('externalUserIrrigation.payment_status') }}</th>
                        <th class="text-center">{{ $t('globalTrans.action') }}</th>
                      </tr>
                      <tr v-for="(pay, index) in paymentList" :key="index">
                        <td>{{ getPumpTypeName(pay.pump_type_id) }}</td>
                        <td>{{ pay.discharge_cusec }}</td>
                        <td>{{ $n(pay.amount) }}</td>
                        <td>
                          <span v-if="pay.payment_type===1" class="badge badge-success">{{ $t('externalUserIrrigation.advance') }}</span>
                          <span v-else class="badge badge-warning">{{ $t('externalUserIrrigation.due') }}</span>
                        </td>
                        <td>
                          <span v-if="pay.payment_status===2" class="badge badge-success">{{ $t('externalUserIrrigation.paid') }}</span>
                          <span v-else class="badge badge-warning">{{ $t('externalUserIrrigation.unpaid') }}</span>
                        </td>
                        <td class="text-center">
                          <div v-if="pay.payment_type==2 && pay.payment_status === 1">
                            <b-button class="btn btn-sm btn-success"
                            @click="schemeSecurityPendingPayment(pay, 0)"
                            >
                                {{ $t('externalUserIrrigation.pay') }}
                            </b-button>
                            <b-button class="btn ml-1 btn-sm btn-warning"
                            @click="schemeSecurityPendingPayment(pay, 1)"
                            >
                                {{ $t('externalUserIrrigation.bypass')}}
                            </b-button>
                          </div>
                        </td>
                      </tr>
                    </table>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col lg="12" sm="12">
                  <h5 class="mb-2" style="font-weight:bold"><i class="ri-list-check"></i> {{ $t('externalUserIrrigation.security_money') + ' ' + $t('globalTrans.list') }}</h5>
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                  <b-form  @submit.prevent="handleSubmit(register)" >
                    <table class="table table-bordered table-sm">
                      <tr>
                        <th>{{ $t('pump_install.participation_category') }}</th>
                        <th>{{ $t('pump_install.discharge_cusec') }}</th>
                        <th>{{ $t('pump_install.amount') }}</th>
                        <th>{{ $t('globalTrans.status') }}</th>
                        <th class="text-center">{{ $t('globalTrans.action') }}</th>
                      </tr>
                      <tr v-for="(pay, index) in paymentList" :key="index">
                        <td>{{ getPumpTypeName(pay.pump_type_id) }}</td>
                        <td>{{ pay.discharge_cusec }}</td>
                        <td>{{ $n(pay.amount) }}</td>
                        <td>
                          <span v-if="pay.payment_type===1" class="badge badge-success">{{ $t('externalUserIrrigation.advance') }}</span>
                          <span v-else class="badge badge-warning">{{ $t('externalUserIrrigation.due') }}</span>
                        </td>
                        <td class="text-center">
                          <div v-if="pay.payment_type==2 && pay.payment_status === 1">
                            <b-button class="btn btn-sm btn-success"
                                @click="addPaymnet(pay)"
                              >
                                {{ $t('globalTrans.add') }}
                            </b-button>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <h5 class="mb-2" style="font-weight:bold"><i class="ri-list-check"></i> {{ $t('externalUserIrrigation.advance') + ' ' +  $t('externalUserIrrigation.payment') + ' ' + $t('globalTrans.list') }}</h5>
                    <table class="table table-bordered table-sm">
                      <tr>
                        <th>{{ $t('pump_install.participation_category') }}</th>
                        <th>{{ $t('pump_install.discharge_cusec') }}</th>
                        <th>{{ $t('pump_install.amount') }}</th>
                        <th>{{ $t('globalTrans.status') }}</th>
                        <th  class="text-center">{{ $t('globalTrans.action') }}</th>
                      </tr>
                      <tr v-for="(pay, index) in advancePayList" :key="index">
                        <td>{{ getPumpTypeName(pay.pump_type_id) }}</td>
                        <td>{{ pay.discharge_cusec }}</td>
                        <td>{{ $n(pay.amount) }}</td>
                        <td>
                          <span v-if="pay.payment_type===1" class="badge badge-success">{{ $t('externalUserIrrigation.advance') }}</span>
                          <span v-else class="badge badge-warning">{{ $t('externalUserIrrigation.due') }}</span>
                        </td>
                        <td class="text-center">
                          <div v-if="pay.payment_type==2">
                            <b-button class="btn btn-sm btn-danger"
                                @click="removePayment(pay.id, index)"
                              >
                                X
                            </b-button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                          <td colspan="2" class="text-right">{{ $t('externalUserIrrigation.total') }}</td>
                          <td>{{ totalAdvance }}</td>
                          <td colspan="2" class="text-center">
                            <b-button type="submit" class="btn btn-sm btn-primary">{{ $t('externalUserIrrigation.pay') }}</b-button>
                            <b-button @click="is_bypass = 1" class="ml-2" type="submit" variant="warning">{{ $t('externalUserIrrigation.bypass')}} {{ $t('externalUserIrrigation.pay') }}</b-button>
                          </td>
                      </tr>
                    </table>
                  </b-form>
                </ValidationObserver>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { badcSchemeAppSecurityFeePayment, badcSchemeAppSecurityFeePaymentDue, getSchemeApplicaionSecurityFee } from '../../api/routes'
export default {
  props: ['applicationId'],
  components: {
    ValidationObserver
  },
  mounted () {
    core.index()
  },
  created () {
    this.getAmount()
  },
  data () {
    return {
      loadingForm: false,
      status: '',
      saveBtnName: this.$t('globalTrans.save'),
      totalAdvance: 0,
      paymentList: [],
      advancePayList: [],
      isdue: false,
      is_bypass: 0
    }
  },
  methods: {
    async register () {
      this.loadingForm = true
      const schemeSecurityMoenyIds = []
      this.advancePayList.map(item => {
        schemeSecurityMoenyIds.push({ id: item.id })
      })
      const payment = {
        amount: this.totalAdvance,
        scheme_application_id: this.applicationId,
        scheme_security_money_id: schemeSecurityMoenyIds,
        is_bypass: this.is_bypass
      }
      let result = null

      result = await RestApi.postData(irriSchemeServiceBaseUrl, badcSchemeAppSecurityFeePayment, payment)
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: true })
      this.loading = false
      if (result.success) {
        if (result.success === 2) {
          this.$toast.success({
            title: 'Success',
            message: result.message
          })
        } else {
          window.location = result.url
        }
        this.$bvModal.hide('modal-security-money')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: result.message
        })
      }
    },
    async schemeSecurityPendingPayment (paymentData, bypass) {
      this.loadingForm = true
      const payment = {
        amount: paymentData.amount,
        scheme_application_id: this.applicationId,
        scheme_security_money_id: [{ id: paymentData.id }],
        is_bypass: bypass
      }
      let result = null
      result = await RestApi.postData(irriSchemeServiceBaseUrl, badcSchemeAppSecurityFeePaymentDue, payment)
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: true })
      this.loadingForm = false
      if (result.success) {
        if (result.success === 2) {
          this.$toast.success({
            title: 'Success',
            message: result.message
          })
        } else {
          window.location = result.url
        }
        this.$bvModal.hide('modal-security-money')
      } else {
        this.$toast.error({
          title: 'Error',
          message: result.message
        })
      }
    },
    getPumpTypeName (id) {
      const singleCat = this.$store.state.ExternalUserIrrigation.pumpTypeList.find(item => item.value === id).text
      return singleCat
    },
    addPaymnet (val) {
      if (val) {
        this.paymentList.find(item => item.id === val.id).payment_status = 2
        this.advancePayList.push(val)
        // update old amount
        this.totalAdvance = this.totalAdvance + val.amount
      }
    },
    removePayment (Id, index) {
      const temp = this.paymentList.find(item => item.id === Id)
      temp.payment_status = 1
      this.totalAdvance = this.totalAdvance - temp.amount
      this.advancePayList.splice(index, 1)
    },
    async getAmount () {
      const param = { scheme_application_id: this.applicationId }
      this.loadingForm = true
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, getSchemeApplicaionSecurityFee, param)
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false })
      this.loadingForm = false
      if (result.success) {
        let totalAmount = 0
        this.paymentList = result.data
         result.data.map(item => {
            if (item.payment_type === 1) {
              totalAmount += item.amount
              this.advancePayList.push(item)
            }
            if (item.payment_status === 2) {
              this.isdue = true
            }
         })
         this.totalAdvance = totalAmount
      }
    }
  }
}
</script>
