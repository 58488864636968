<template>
  <b-container fluid>
    <b-overlay :show="logingForm">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col lg="11" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                  <b-form  @submit.prevent="handleSubmit(register)" >
                    <table class="table table-bordered table-sm">
                      <tr >
                        <td style="width:40%"><h6 class="ml-2">{{ $t('externalUserIrrigation.amount') }}</h6></td>
                        <td class="text-center"><h5>{{ $n(payment.amount) }}</h5></td>
                      </tr>
                    </table>
                    <b-row>
                      <b-col>
                        <b-button class="btn-block" type="submit" variant="primary">{{ $t('globalTrans.pay') }}</b-button>
                      </b-col>
                      <b-col>
                          <b-button @click="payment.is_bypass = 1" class="btn-block" type="submit" variant="warning">{{ $t('externalUserIrrigation.bypass')}} {{ saveBtnName}}</b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { badcSchemeAppApplicationFeeOtherPayment, getSchemeApplicaionApplicationFeeOther } from '../../api/routes'

export default {
  props: ['application'],
  components: {
    ValidationObserver
  },
  mounted () {
    core.index()
  },
  created () {
    this.getAmount(this.schemeTypeId)
  },
  data () {
    return {
      logingForm: false,
      saveBtnName: this.$t('globalTrans.save'),
      payment: {
        org_id: this.application.org_id,
        master_payment_id: '',
        scheme_application_id: this.application.id,
        amount: '',
        is_bypass: 0
      }
    }
  },
  computed: {
    loading () {
      return this.$store.state.ExternalUserIrrigation.static.loading
    }
  },
  methods: {
    async register () {
      this.logingForm = true
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true })
      let result = null
      result = await RestApi.postData(irriSchemeServiceBaseUrl, badcSchemeAppApplicationFeeOtherPayment, this.payment)
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: true })
      this.logingForm = false
      if (result.success) {
        if (result.success === 2) {
          this.$toast.success({
            title: 'Success',
            message: result.message
          })
        } else {
          window.location = result.url
        }
        this.$bvModal.hide('modal-other-application-fee')
      } else {
        this.$toast.error({
          title: 'Error',
          message: result.message
        })
      }
    },
    async getAmount () {
      this.logingForm = true
      const param = { org_id: this.application.org_id }
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, getSchemeApplicaionApplicationFeeOther, param)
      this.logingForm = false
      if (result.success) {
        this.payment.master_payment_id = result.data.id
        this.payment.amount = result.data.amount
      }
    }
  }
}
</script>
