<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-col lg="12" md="12" sm="12" class="text-dark">
          <iq-card style="padding:15px;">
            <template>
                <b-row>
                    <b-col lg="12" sm="12" xs="12" class="mb-1 text-right">
                        <b-button @click="pdfExport" class="btn btn-sm btn-success">
                            <i class="ri-printer-line"></i> {{  $t('globalTrans.print') }}
                        </b-button>
                    </b-col>
                    <b-col class="details-border">
                        <b-row>
                            <b-col lg="9" sm="12" xs="12">
                            <b-row style="font-weight:600">
                                <b-col lg="2" sm="12">
                                <p>{{ $t('pump_install.application_id') }}</p>
                                </b-col>
                                <b-col lg="1" sm="12">
                                <p>:</p>
                                </b-col>
                                <b-col lg="9" sm="12">
                                <p>{{ application.application_id }}</p>
                                </b-col>
                                <b-col lg="2" sm="12">
                                <p>{{ $t('globalTrans.date') }} </p>
                                </b-col>
                                <b-col lg="1" sm="12">
                                <p>:</p>
                                </b-col>
                                <b-col lg="9" sm="12">
                                <p>{{ application.created_at | dateFormat }}</p>
                                </b-col>
                                <b-col lg="2" sm="12">
                                <p>{{ $t('pump_install.organization') }}</p>
                                </b-col>
                                <b-col lg="1" sm="12">
                                <p>:</p>
                                </b-col>
                                <b-col lg="9" sm="12">
                                <p>{{ getOrganization(application.org_id) }}</p>
                                </b-col>
                                <b-col lg="2" sm="12">
                                <p>{{ $t('globalTrans.address') }}</p>
                                </b-col>
                                <b-col lg="1" sm="12">
                                <p>:</p>
                                </b-col>
                                <b-col lg="9" sm="12">
                                <p>{{ getPumpAddress(application) }}</p>
                                </b-col>
                            </b-row>
                            </b-col>
                            <b-col lg="3" sm="12" xs="12">
                            <img :src="baseUrl + 'download-attachment?file=uploads/scheme-application/scheme-manager-photo/original/' + application.sch_man_photo" :alt="application.name" class="height-150 width-150 img-fluid" style="border: 1px solid; float:right; margin-bottom:20px"/>
                            </b-col>
                            <b-col lg="12" sm="12" style="margin-top:20px; margin-bottom: 20px;">
                            <p>{{ $t('pump_install.dear_sir') }},</p>
                            <p>{{ $t('pump_install.i_am_writing') }}</p>
                            </b-col>
                            <b-col lg="12" sm="12" style="margin-bottom: 40px;">
                            <p>{{ $t('pump_install.i_would_appreciate') }}</p>
                            </b-col>
                        </b-row>
                        <b-row style="font-weight:600">
                            <b-col lg="12" sm="12">
                            <p>{{ $t('pump_install.sincerely') }} : </p>
                            </b-col>
                            <b-col lg="2" sm="12">
                            <p>{{ $t('pump_install.father_name') }}</p>
                            </b-col>
                            <b-col lg="1" sm="12">
                            <p>:</p>
                            </b-col>
                            <b-col lg="9" sm="12">
                            <p>{{ ($i18n.locale == 'bn') ? application.father_name_bn : application.father_name }}</p>
                            </b-col>
                            <b-col lg="2" sm="12">
                            <p>{{ $t('pump_install.no_of_water_user') }}</p>
                            </b-col>
                            <b-col lg="1" sm="12">
                            <p>:</p>
                            </b-col>
                            <b-col lg="9" sm="12">
                            <p>{{ $n(application.total_farmer) }}</p>
                            </b-col>
                            <b-col lg="2" sm="12">
                            <p>{{ $t('pump_install.mobile') }}</p>
                            </b-col>
                            <b-col lg="1" sm="12">
                            <p>:</p>
                            </b-col>
                            <b-col lg="9" sm="12">
                            <p>{{ application.far_mobile_no | mobileNumber}}</p>
                            </b-col>
                            <!-- <b-col lg="2" sm="12">
                            <p>{{ $t('pump_install.email') }}</p>
                            </b-col>
                            <b-col lg="1" sm="12">
                            <p>:</p>
                            </b-col>
                            <b-col lg="9" sm="12">
                            <p>{{ application.email }}</p>
                            </b-col> -->
                            <b-col lg="12" sm="12" style="margin-top:20px">
                            <p class="text-center">[{{ $t('pump_install.this_is_computer_generated') }}]</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                            <h5 class='complain-title'>{{ $t('pump_install.application_details') }}</h5>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="12">
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.application_id') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                <p class="text-dark">{{ application.application_id }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.name') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ application.name }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.nid') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ application.nid | mobileNumber }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('irrigation_config.sub_scheme_type_list') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ getSubSchemeType(application.sub_scheme_type_id) }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.father_name') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.father_name_bn : application.father_name }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.division') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ appHierarchyNames.divisionName }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.upazila') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ appHierarchyNames.upazilaName }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.village') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.far_village_bn : application.far_village }}</p>
                                </b-col>
                            </b-row>
                            </b-col>
                            <b-col lg="6" sm="12">
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.organization') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ getOrganization(application.org_id) }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.irrigation_type') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.scheme_type_name_bn : application.scheme_type_name }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('irrigation_config.pump_capacity') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ getPumpCapacity(application.pump_capacity_id) }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.mother_name') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.mother_name_bn : application.mother_name }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.district') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ appHierarchyNames.districtName }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.union') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ appHierarchyNames.unionName }}</p>
                                </b-col>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.mobile') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ application.far_mobile_no | mobileNumber }}</p>
                                </b-col>
                            </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                            <h5 class='complain-title'>{{ $t('pump_install.scheme_man_details') }}</h5>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="12">
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.name') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ (this.$i18n.locale === 'bn') ? application.sch_man_name_bn : application.sch_man_name }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.mobile') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ application.sch_man_mobile_no | mobileNumber }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.father_name') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.sch_man_father_name_bn : application.sch_man_father_name }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.division') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ schemeHierarchyNames.divisionName }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.upazila') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ schemeHierarchyNames.upazilaName }}</p>
                                </b-col>
                            </b-row>
                            </b-col>
                            <b-col lg="6" sm="12">
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.nid') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ application.sch_man_nid }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.mother_name') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.sch_man_mother_name_bn : application.sch_man_mother_name }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.district') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ schemeHierarchyNames.districtName }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.union') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ schemeHierarchyNames.unionName }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.village') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.sch_man_village_bn : application.sch_man_village }}</p>
                                </b-col>
                            </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                            <h5 class='complain-title'>{{ $t('pump_install.pump_details') }}</h5>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="12">
                            <b-row style="font-weight:bold; font-size:20px;" v-if="application.status == 14">
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.pump_id') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ application.pump_id }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.division') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ schemeHierarchyNames.divisionName }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.upazila') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ schemeHierarchyNames.upazilaName }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.mauza_no') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.pump_mauza_no_bn : application.pump_mauza_no }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.plot_no') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.pump_plot_no_bn : application.pump_plot_no }}</p>
                                </b-col>
                            </b-row>
                            </b-col>
                            <b-col lg="6" sm="12">
                            <b-row v-if="application.status == 14">
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.installed_date') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ application.updated_at | dateFormat }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.district') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ schemeHierarchyNames.districtName }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.union') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ schemeHierarchyNames.unionName }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.pump_jl_no') }} : </p>
                                </b-col>
                                <b-col lg="7" sm="12">
                                    <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.pump_mauza_no_bn : application.pump_mauza_no }}</p>
                                </b-col>
                            </b-row>
                            </b-col>
                        </b-row>
                        <b-row v-if="application.project_id !== null">
                            <b-col lg="12" sm="12" class="text-center mb-4">
                            <h5 class='complain-title'>{{ $t('pump_install.project') }}</h5>
                            </b-col>
                            <b-col lg="12" sm="12" class>
                            <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.project_name_bn : application.project_name }}</p>
                            </b-col>
                        </b-row>
                        <b-row v-if="application.survey_id !== null">
                            <b-col lg="12" sm="12" class="text-center mb-4">
                            <h5 class='complain-title'>{{ $t('pump_install.survey') }}</h5>
                            </b-col>
                            <b-col lg="12" sm="12">
                            <b-row>
                                <b-col lg="2" sm="12">
                                <p class="text-dark">{{ $t('pump_install.survey_date') }} : </p>
                                </b-col>
                                <b-col lg="10" sm="12">
                                <p class="text-dark">{{ application.survey_date | dateFormat }} </p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="2" sm="12">
                                <p class="text-dark">{{ $t('pump_install.suggestionl') }} : </p>
                                </b-col>
                                <b-col lg="10" sm="12">
                                <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.suggestion_bn : application.suggestion }} </p>
                                </b-col>
                            </b-row>
                            </b-col>
                        </b-row>
                        <b-row v-if="survey_notes.length > 0">
                            <b-col lg="12" sm="12" class="text-center mb-4">
                            <h5 class='complain-title'>{{ $t('pump_install.survey_note') }}</h5>
                            </b-col>
                            <b-col lg="12" sm="12">
                            <b-table-simple striped bordered small>
                                <b-tr>
                                <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th class="text-center">{{ $t('pump_install.note') }}</b-th>
                                </b-tr>
                                <b-tr v-for="(survey_note,index) in survey_notes" :key="index">
                                <b-td>{{ $n(index + 1) }}</b-td>
                                <b-td>{{ ($i18n.locale == 'bn') ? survey_note.note_bn : survey_note.note }}</b-td>
                                </b-tr>
                            </b-table-simple>
                            </b-col>
                        </b-row>
                        <b-row v-if="application.license_id !== null">
                            <b-col lg="12" sm="12" class="text-center mb-4">
                                <h5 class='complain-title'>{{ $t('pump_install.license') }}</h5>
                            </b-col>
                            <b-col lg="12" sm="12">
                                <b-row>
                                    <b-col lg="2" sm="12">
                                    <p class="text-dark">{{ $t('pump_install.license_no') }} : </p>
                                    </b-col>
                                    <b-col lg="10" sm="12">
                                    <p class="text-dark">{{ application.license_no }} </p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="2" sm="12">
                                    <p class="text-dark">{{ $t('pump_install.issue_date') }} : </p>
                                    </b-col>
                                    <b-col lg="10" sm="12">
                                    <p class="text-dark">{{ application.license_issue_date | dateFormat }} </p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="2" sm="12">
                                    <p class="text-dark">{{ $t('pump_install.attachment') }} : </p>
                                    </b-col>
                                    <b-col lg="10" sm="12">
                                    <p class="text-dark">
                                        <a :href="baseUrl + 'download-attachment?file=uploads/pump-installation/license/original/' + application.license_attachment" title="License"><i class="ri-download-cloud-fill"></i></a>
                                    </p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row v-if="progress_types.length > 0 && progress_types[0].far_pump_install.length > 0">
                            <b-col lg="12" sm="12" class="text-center mb-4">
                                <h5 class='complain-title'>{{ $t('pump_install.pump_installation_progress') }}</h5>
                            </b-col>
                            <b-col lg="12" sm="12">
                                <b-table-simple striped bordered small>
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th>{{ $t('pump_install.step_name') }}</b-th>
                                        <b-th>{{ $t('pump_install.start_date') }}</b-th>
                                        <b-th>{{ $t('pump_install.end_date') }}</b-th>
                                        <b-th>{{ $t('pump_install.notel') }}</b-th>
                                    </b-tr>
                                    <b-tr v-for="(progress_type,index) in progress_types" :key="index">
                                        <b-td>{{ $n(index + 1) }}</b-td>
                                        <b-td>{{ ($i18n.locale == 'bn') ? progress_type.step_name_bn : progress_type.step_name }}</b-td>
                                        <b-td>{{ progress_type.far_pump_install[0] !== undefined ? progress_type.far_pump_install[0].start_date : '' | dateFormat}}</b-td>
                                        <b-td>{{ progress_type.far_pump_install[0] !== undefined ? progress_type.far_pump_install[0].end_date : '' | dateFormat }}</b-td>
                                        <b-td>{{ progress_type.far_pump_install[0] !== undefined ? ($i18n.locale == 'bn') ? progress_type.far_pump_install[0].note_bn : progress_type.far_pump_install[0].note : '' }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { applicationShow } from '../../api/routes'
import HierarchycalDropdownNames from '@/Utils/common-external'
import Pdf from './pdf.js'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getSchemeApplicationDetails()
      Object.freeze(tmp)
      this.application = tmp
    }
  },
  data () {
    return {
        loader: true,
        appHierarchyNames: null,
        pumpHierarchyNames: null,
        schemeHierarchyNames: null,
        application: '',
        survey_notes: [],
        progress_types: [],
        baseUrl: irriSchemeServiceBaseUrl
    }
  },
  computed: {
    applicationData () {
      return Object.assign({}, this.application, this.appHierarchyNames, this.pumpHierarchyNames, this.schemeHierarchyNames)
    }
  },
  methods: {
    async getSchemeApplicationDetails () {
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, `${applicationShow}/${this.$props.id}`)
      if (result.success) {
        this.application = this.getRelationalData(result)
        this.survey_notes = result.survey_notes
        this.progress_types = result.progress_types
        this.appHierarchyNames = HierarchycalDropdownNames.getCommonHierarchyNames(this.application.far_union_id, 'union')
        this.pumpHierarchyNames = HierarchycalDropdownNames.getCommonHierarchyNames(this.application.pump_union_id, 'union')
        this.schemeHierarchyNames = HierarchycalDropdownNames.getCommonHierarchyNames(this.application.sch_man_union_id, 'union')
      }
      this.loader = false
    },
    getRelationalData (result) {
      const organizationList = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
        const orgObject = organizationList.find(oganization => oganization.value === result.data.org_id)
        const orgData = {
          org_name: orgObject !== undefined ? orgObject.text_en : '',
          org_name_bn: orgObject !== undefined ? orgObject.text_bn : ''
        }
      const subSchemeTypeList = this.$store.state.ExternalUserIrrigation.subSchemeTypeList
      const subScheme = subSchemeTypeList.find(sub => sub.value === result.data.sub_scheme_type_id)
      const subSchemeData = {
        sub_scheme_name: subScheme !== undefined ? subScheme.text_en : '',
        sub_scheme_name_bn: subScheme !== undefined ? subScheme.text_bn : ''
      }
      const pumpCapacityList = this.$store.state.ExternalUserIrrigation.pumpCapacityList
      const pumpCapacity = pumpCapacityList.find(capacity => capacity.value === result.data.pump_capacity_id)
      const pumpCapacityData = {
        pump_capacity: pumpCapacity !== undefined ? pumpCapacity.text_en : '',
        pump_capacity_bn: pumpCapacity !== undefined ? pumpCapacity.text_bn : ''
      }
      const surveyNoteData = {
        survey_notes: result.survey_notes
      }
      const progressTypesData = {
        progress_types: result.progress_types
      }
      return Object.assign({}, result.data, surveyNoteData, progressTypesData, pumpCapacityData, orgData, subSchemeData)
    },
    getOrganization (orgId) {
        const orgList = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
        const org = orgList.find(orgItem => orgItem.value === orgId)
        if (this.$i18n.locale === 'bn') {
          return org.text_bn
        } else {
          return org.text_en
        }
    },
    getSubSchemeType (subSchemeTypeId) {
      const subSchemeTypeList = this.$store.state.ExternalUserIrrigation.subSchemeTypeList
      const subScheme = subSchemeTypeList.find(sub => sub.value === subSchemeTypeId)
      if (this.$i18n.locale === 'bn') {
        return subScheme !== undefined ? subScheme.text_bn : ''
      } else {
        return subScheme !== undefined ? subScheme.text_en : ''
      }
    },
    getPumpCapacity (pumpCapacityId) {
      const pumpCapacityList = this.$store.state.ExternalUserIrrigation.pumpCapacityList
      const pumpCapacity = pumpCapacityList.find(capacity => capacity.value === pumpCapacityId)
      if (typeof pumpCapacity === 'undefined') {
        return ''
      } else {
        return pumpCapacity.text_en
      }
    },
    getPumpAddress (item) {
      const address = []
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.pump_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.pump_mauza_no_bn, item.pump_jl_no, item.pump_plot_no, tmpUnion !== undefined ? tmpUnion.text_bn : '')
      } else {
        address.push(item.pump_mauza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion !== undefined ? tmpUnion.text_en : '')
      }
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.pump_upazilla_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.pump_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.ExternalUserIrrigation.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.pump_division_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    },
    pdfExport () {
        const reportTitle = this.$t('pump_install.application_details')
        Pdf.exportPdfDetails(reportTitle, this, this.applicationData)
    }
  }
}
</script>
<style scoped>
    .details-border {
        border: 1px solid gray;
        padding: 8px;
        border-radius: 5px;
        box-shadow: 1px 2px 3px gray;
    }
</style>
