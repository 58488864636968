<template>
  <b-container fluid>
    <b-row v-if="loadMsg">
        <b-col>
            <b-overlay :show='true'>
                <h4 class="text-center">{{ $t('globalTrans.loading') }} ...</h4>
            </b-overlay>
        </b-col>
    </b-row>
    <div v-else>
        <b-row v-if="overlay" variant="default">
            <div class="box-message col-md-12 mt-5 text-danger">
                <div class="arrow">
                    <div class="outer"></div>
                    <div class="inner"></div>
                </div>
                <div class="message-body">
                    <p class="pb-2 pt-2 h5"><i class="ri-alert-fill mr-2"></i>
                    <!-- {{ $t('externalUserIrrigation.dashboard_message') }} -->
                    {{ $t('externalPanel.profile_complete_msg_part1')}}
                    <router-link :to="{ path: '/irrigation/my-profile' }">
                      {{ $t('externalPanel.profile_complete_msg_part2')}}
                    </router-link>
                    {{ $t('externalPanel.profile_complete_msg_part3')}}
                    </p>
                </div>
            </div>
        </b-row>
        <div v-else>
            <b-row>
                <b-col md="12">
                  <b-modal id="modal-7" size="lg" :title="$t('pump_install.application_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <ApplicationDetails :id="currentAppId"/>
                  </b-modal>
                  <!-- application fee other for org -->
                  <b-modal id="modal-other-application-fee" size="md" :title="$t('externalUserIrrigation.form_fee')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <FromFeeOther :application="application"/>
                  </b-modal>
                  <!-- payment for BMDC -->
                  <b-modal id="modal-badc-application-fee" size="md" :title="$t('externalUserIrrigation.form_fee')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <ApplicationFeeBadc :applicationId="applicationId" :schemeTypeId="schemeTypeId"/>
                  </b-modal>
                  <b-modal id="modal-badc-part-fee" size="lg" :title="$t('externalUserIrrigation.participation_Fee')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <ParticipationPayment :applicationId="applicationId"/>
                  </b-modal>
                  <b-modal id="modal-security-money" size="lg" :title="$t('externalUserIrrigation.security_money')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <SecurityPayment :applicationId="applicationId"/>
                  </b-modal>
                  <!-- payment for BMDA -->
                  <b-modal id="modal-bmda-application-fee" size="md" :title="$t('externalUserIrrigation.form_fee')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <FormFeeBmda :applicationId="applicationId" :schemeTypeId="schemeTypeId"/>
                  </b-modal>
                  <b-modal id="modal-bmda-part-fee" size="lg" :title="$t('externalUserIrrigation.participation_Fee')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <ParticipationPaymentBmda :applicationId="applicationId"/>
                  </b-modal>
                  <!-- Application Details -->
                  <b-modal id="farmer-form" size="xl" :title="$t('externalUserIrrigation.farmer_info')"
                          :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <FarmerForm :appId="currentAppId" @added="loadData()" />
                  </b-modal>
                  <b-modal id="farmer-details-list" size="xl" :title="$t('externalUserIrrigation.farmer_info')"
                          :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <FarmerDetailsList :appId="currentAppId" />
                  </b-modal>
                  <b-modal id="modal-money-receipt" size="md" :title="$t('externalUserIrrigation.money_receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <MoneyReceipt :appId="currentAppId" :applicationType="applicationType" :paymentTypeTd="paymentTypeTd" />
                  </b-modal>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('externalUserIrrigation.scheme_application') }}</h4>
                    </template>
                    <template v-slot:body>
                    <b-overlay :show="loadview">
                        <b-row>
                            <b-col>
                                <div class="stepper-wrapper" v-if="schemeApp">
                                    <div :class="(schemeApp.status >= 1) ? `stepper-item completed`: 'stepper-item'">
                                        <div class="step-counter"><i v-if="schemeApp.status > 1" class="ri-check-fill"></i> <span v-else>{{ $n(1) }}</span></div>
                                        <div class="step-name">{{ getStatus(1) }}</div>
                                    </div>
                                    <div :class="(schemeApp.status >= 2) ? `stepper-item completed`: 'stepper-item'">
                                        <div class="step-counter"><i v-if="schemeApp.status > 2" class="ri-check-fill"></i> <span v-else>{{ $n(2) }}</span></div>
                                        <div class="step-name">{{ getStatus(2) }}</div>
                                    </div>
                                    <div :class="(schemeApp.status >= 3) ? `stepper-item completed`: 'stepper-item'">
                                        <div class="step-counter"><i v-if="schemeApp.status > 3" class="ri-check-fill"></i> <span v-else>{{ $n(3) }}</span></div>
                                        <div class="step-name">{{ getStatus(3) }}</div>
                                    </div>
                                    <div v-if="schemeApp.status === 4" :class="(schemeApp.status >= 4) ? `stepper-item completed`: 'stepper-item'">
                                        <div class="step-counter"><i v-if="schemeApp.status > 4" class="ri-check-fill"></i> <span v-else>{{ $n(4) }}</span></div>
                                        <div class="step-name">{{ getStatus(4) }}</div>
                                    </div>
                                    <div v-if="schemeApp.status === 5" :class="(schemeApp.status >= 5) ? `stepper-item completed`: 'stepper-item'">
                                        <div class="step-counter"><i v-if="schemeApp.status > 5" class="ri-check-fill"></i> <span v-else>{{ $n(5) }}</span></div>
                                        <div class="step-name">{{ getStatus(5) }}</div>
                                    </div>
                                    <slot v-if="schemeApp.status > 5">
                                      <div :class="(schemeApp.status >= 6) ? `stepper-item completed`: 'stepper-item'">
                                          <div class="step-counter"><i v-if="schemeApp.status > 6" class="ri-check-fill"></i> <span v-else>{{ $n(6) }}</span></div>
                                          <div class="step-name">{{ getStatus(6) }}</div>
                                      </div>
                                      <div :class="(schemeApp.status >= 7) ? `stepper-item completed`: 'stepper-item'">
                                          <div class="step-counter"><i v-if="schemeApp.status > 7" class="ri-check-fill"></i> <span v-else>{{ $n(7) }}</span></div>
                                          <div class="step-name">{{ getStatus(7) }}</div>
                                      </div>
                                      <div :class="(schemeApp.status >= 8) ? `stepper-item completed`: 'stepper-item'">
                                          <div class="step-counter"><i v-if="schemeApp.status > 8" class="ri-check-fill"></i> <span v-else>{{ $n(8) }}</span></div>
                                          <div class="step-name">{{ getStatus(8) }}</div>
                                      </div>
                                      <div :class="(schemeApp.status >= 12) ? `stepper-item completed`: 'stepper-item'">
                                          <div class="step-counter"><i v-if="schemeApp.status > 12" class="ri-check-fill"></i> <span v-else>{{ $n(12) }}</span></div>
                                          <div class="step-name">{{ getStatus(12) }}</div>
                                      </div>
                                      <div :class="(schemeApp.status >= 9) ? `stepper-item completed`: 'stepper-item'">
                                          <div class="step-counter"><i v-if="schemeApp.status > 9" class="ri-check-fill"></i> <span v-else>{{ $n(9) }}</span></div>
                                          <div class="step-name">{{ getStatus(9) }}</div>
                                      </div>
                                      <div :class="(schemeApp.status >= 10) ? `stepper-item completed`: 'stepper-item'">
                                          <div class="step-counter"><i v-if="schemeApp.status > 10" class="ri-check-fill"></i> <span v-else>{{ $n(10) }}</span></div>
                                          <div class="step-name">{{ getStatus(10) }}</div>
                                      </div>
                                      <div :class="(schemeApp.status >= 11) ? `stepper-item completed`: 'stepper-item'">
                                          <div class="step-counter"><i v-if="schemeApp.status > 11" class="ri-check-fill"></i> <span v-else>{{ $n(11) }}</span></div>
                                          <div class="step-name">{{ getStatus(11) }}</div>
                                      </div>
                                      <div :class="(schemeApp.status >= 14) ? `stepper-item completed`: 'stepper-item'">
                                          <div class="step-counter"><i v-if="schemeApp.status > 14" class="ri-check-fill"></i> <span v-else>{{ $n(14) }}</span></div>
                                          <div class="step-name">{{ getStatus(14) }}</div>
                                      </div>
                                    </slot>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-table-simple bordered>
                              <tr>
                                <th>{{ $t('externalUserIrrigation.farmer_info') }}</th>
                                <th>{{ $t('externalUserIrrigation.form_fee') }}</th>
                                <th>{{ $t('externalUserIrrigation.participation_Fee') }}</th>
                                <th>{{ $t('externalUserIrrigation.security_money') }}</th>
                                <th>{{ $t('globalTrans.action') }}</th>
                              </tr>
                              <tr>
                                <td class="farmer-info">
                                  <b-button href="javascript:"
                                    class="btn-outline-success mt-1"
                                    variant=" iq-bg-success ml-1"
                                    size="sm"
                                    v-b-tooltip.hover
                                    @click="openFarDetails(schemeApp)"
                                    title="View Details">
                                    {{ $t('globalTrans.added') }}: <span class="text-success">{{ schemeApp.added_farmer != null ? $n(schemeApp.added_farmer) : $n(0) }}</span>
                                  </b-button>
                                  <b-button
                                      v-if="schemeApp.total_farmer > schemeApp.added_farmer"
                                      v-b-modal.farmer-form
                                      class="btn-outline-success mt-1"
                                      variant=" iq-bg-success ml-1"
                                      size="sm"
                                      title="Add Details"
                                      @click="view(schemeApp)">
                                      {{ $t('globalTrans.add_new') }} +
                                  </b-button>
                                </td>
                                <td class="form-fee">
                                  <b-button v-if="schemeApp.org_id !== 3 && schemeApp.org_id !== 15 && schemeApp.payment_status === 0"
                                    class="btn-outline-success mt-1"
                                    variant=" iq-bg-success ml-1"
                                    size="sm"
                                    title="Pending Payment"
                                    @click="applicationFeeOther(schemeApp)">
                                      {{ $t('externalUserIrrigation.form_fee') }}
                                  </b-button>
                                  <b-button v-if="schemeApp.org_id===3 && schemeApp.payment_status === 0"
                                    class="btn-outline-success mt-1"
                                    variant=" iq-bg-success ml-1"
                                    size="sm"
                                    @click="badcApplicationFee(schemeApp)">
                                      {{ $t('externalUserIrrigation.form_fee') }}
                                  </b-button>
                                  <b-button v-if="schemeApp.org_id === 15 && schemeApp.payment_status === 0"
                                    class="btn-outline-success mt-1"
                                    variant=" iq-bg-success ml-1"
                                    size="sm"
                                    @click="bmdaFormFee(schemeApp)">
                                    {{ $t('externalUserIrrigation.form_fee') }}
                                  </b-button>
                                  <b-button v-if="schemeApp.payment_status === 1"
                                    class="btn-outline-success mt-1"
                                    variant=" iq-bg-success ml-1"
                                    size="sm"
                                    @click="showMoneyReceipt(schemeApp, 1, 1)"
                                  >
                                    {{ $t('externalUserIrrigation.form_fee') }}
                                  </b-button>
                                </td>
                                <td class="participation-fee">
                                  <b-button v-if="schemeApp.org_id === 3 && schemeApp.status === 12 && schemeApp.participation_payment_status === 1"
                                    class="btn-outline-success mt-1"
                                    variant=" iq-bg-success ml-1"
                                    size="sm"
                                    @click="participationPayment(schemeApp)">
                                    {{ $t('externalUserIrrigation.participation_Fee') }}
                                  </b-button>
                                  <b-button v-if="schemeApp.org_id === 15 && schemeApp.status === 12 && schemeApp.participation_payment_status === 1"
                                    class="btn-outline-success mt-1"
                                    variant=" iq-bg-success ml-1"
                                    size="sm"
                                    @click="bmdaParticipationPayment(schemeApp)">
                                    {{ $t('externalUserIrrigation.participation_Fee') }}
                                  </b-button>
                                  <b-button
                                    v-if="schemeApp.participation_payment_status === 2"
                                    class="btn-outline-success mt-1"
                                    variant=" iq-bg-success ml-1"
                                    size="sm"
                                    @click="showMoneyReceipt(schemeApp, 1, 2)">
                                    {{ $t('externalUserIrrigation.participation_Fee') }}
                                  </b-button>
                                </td>
                                <td class="security-fee">
                                  <b-button v-if="schemeApp.org_id === 3 && schemeApp.status === 12 && schemeApp.security_payment_status === 1"
                                      class="btn-outline-success mt-1"
                                      variant=" iq-bg-success ml-1"
                                      size="sm"
                                      @click="securityPayment(schemeApp)">
                                    {{ $t('externalUserIrrigation.security_money') }}
                                  </b-button>
                                  <b-button
                                    v-if="schemeApp.security_payment_status === 2"
                                    class="btn-outline-success mt-1"
                                    variant=" iq-bg-success ml-1"
                                    size="sm"
                                    @click="showMoneyReceipt(schemeApp, 1, 3)">
                                    {{ $t('externalUserIrrigation.security_money') }}
                                  </b-button>
                                </td>
                                <td class="action">
                                  <b-button v-if="schemeApp.payment_status === 0 || schemeApp.payment_status === 4 || schemeApp.payment_status === 5" class="btn btn-outline-success mt-1" variant=" iq-bg-success" size="sm">
                                    <router-link :to="'/irrigation/scheme-application-submit/' + schemeApp.id">
                                      <i class="ri-ball-pen-fill m-0"></i>
                                    </router-link>
                                  </b-button>
                                  <b-button v-if="schemeApp.rating_status !== 1"
                                    class="btn-outline-success ml-1"
                                    title="Rate This Application"
                                    v-b-modal.modal-rating variant="
                                    iq-bg-success" size="sm" @click="rating(schemeApp)">
                                    {{ $t('external_sidebar.rating') }}
                                  </b-button>
                                </td>
                              </tr>
                            </b-table-simple>
                          </b-col>
                        </b-row>
                        <slot v-if="schemeApp !== ''">
                          <ApplicationDetails :id="schemeApp.id"/>
                        </slot>
                    </b-overlay>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
        </div>
    </div>
    <b-modal id="modal-rating" size="md" :title="$t('external_sidebar.rating')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Rating :application="singleApp"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { farmerSchemeApplication, farmerInfo, irriSchemePayment } from '../../api/routes'
import ApplicationDetails from './ApplicationDetails'
import ApplicationFeeBadc from './ApplicationFeeBadc'
import FormFeeBmda from './FormFeeBmda'
import SecurityPayment from './SecurityPayment'
import ParticipationPayment from './ParticipationPayment'
import ParticipationPaymentBmda from './ParticipationPaymentBmda'
import FromFeeOther from './FromFeeOther'
import FarmerForm from './scheme-farmer-land-details/FarmerForm'
import FarmerDetailsList from './scheme-farmer-land-details/FarmerDetailsList'
import Rating from '../../components/Rating'
import MoneyReceipt from './MoneyReceipt'

export default {
  name: 'UiDataTable',
  components: {
    ApplicationDetails,
    FarmerForm,
    FarmerDetailsList,
    ApplicationFeeBadc,
    ParticipationPayment,
    SecurityPayment,
    FromFeeOther,
    FormFeeBmda,
    ParticipationPaymentBmda,
    Rating,
    MoneyReceipt
  },
  data () {
    return {
        loadMsg: false,
        loadview: false,
        pagination: {
          currentPage: 1,
          totalRows: 0,
          perPage: this.$store.state.ExternalUserIrrigation.static.perPage,
          slOffset: 1
        },
        search: {},
        currentAppId: 0,
        applicationType: 0,
        paymentTypeTd: 0,
        rows: [],
        districtList: [],
        upazilaList: [],
        unionList: [],
        overlay: true,
        baseUrl: irriSchemeServiceBaseUrl,
        singleApp: null,
        applicationId: null,
        schemeTypeId: null,
        application: null,
        schemeApp: ''
    }
  },
  computed: {
    default () {
      return {
        id: this.rows.length
      }
    },
    formTitle () {
       return (this.currentAppId === 0) ? this.$t('org_pro.office') + ' ' + this.$t('globalTrans.entry') : this.$t('org_pro.office') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.ExternalUserIrrigation.static.loading
    },
    listReload () {
      return this.$store.state.ExternalUserIrrigation.static.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('pump_install.application_id'), class: 'text-center' },
          { label: this.$t('pump_install.scheme_manager'), class: 'text-center' },
          { label: this.$t('irrigation_config.scheme_typel'), class: 'text-center' },
          { label: this.$t('pump_install.view_application'), class: 'text-center' },
          { label: this.$t('warehouse_report.total_farmer'), class: 'text-center' },
          { label: this.$t('pump_install.attached_document_link'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
        { key: 'index' },
          { key: 'application_id' },
          { key: 'sch_man_name_bn' },
          { key: 'scheme_type_name_bn' },
          { key: 'view_application' },
          { key: 'total_farmer' },
          { key: 'attached_document_link' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
         { key: 'index' },
          { key: 'application_id' },
          { key: 'sch_man_name' },
          { key: 'scheme_type_name' },
          { key: 'view_application' },
          { key: 'total_farmer' },
          { key: 'attached_document_link' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.checkUser().then((res) => {
      if (!res.loggedFarmerId) {
        this.overlay = true
     } else {
       this.loadData()
      }
    })
    this.paymentStatusUpdate()
  },
  mounted () {
    core.index()
  },
  methods: {
    rating (item) {
      this.singleApp = item
      this.singleApp.application_type = 1
      this.$bvModal.show('modal-rating')
    },
    openFarDetails (item) {
      this.currentAppId = item.id
      this.$bvModal.show('farmer-details-list')
    },
    applicationFeeOther (item) {
      this.application = item
      this.$bvModal.show('modal-other-application-fee')
    },
    badcApplicationFee (item) {
      this.applicationId = item.id
      this.schemeTypeId = item.scheme_type_id
      this.$bvModal.show('modal-badc-application-fee')
    },
    participationPayment (item) {
      this.applicationId = item.id
      this.$bvModal.show('modal-badc-part-fee')
    },
    securityPayment (item) {
      this.applicationId = item.id
      this.$bvModal.show('modal-security-money')
    },
    bmdaFormFee (item) {
      this.applicationId = item.id
      this.schemeTypeId = item.scheme_type_id
      this.$bvModal.show('modal-bmda-application-fee')
    },
    bmdaParticipationPayment (item) {
      this.applicationId = item.id
      this.$bvModal.show('modal-bmda-part-fee')
    },
    showMoneyReceipt (item, appType, paymentTypeTd) {
      this.currentAppId = item.id
      this.applicationType = appType
      this.paymentTypeTd = paymentTypeTd
      this.$bvModal.show('modal-money-receipt')
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    view (item) {
      this.currentAppId = item.id
    },
    loadData () {
      this.loadview = true
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(irriSchemeServiceBaseUrl, farmerSchemeApplication, params).then(response => {
        if (response.success) {
           this.loadview = false
           if (response.data.data.length < 1) {
              this.$router.push('/irrigation/scheme-application-submit')
           }
          this.$store.dispatch('ExternalUserIrrigation/getSchemeApplicationList', response.data.data)
          this.getSchemeApp()
          this.paginationData(response.data)
          if (response.data.data[0].rating_status === 0 && response.data.data[0].status === 14) {
            this.rating(this.schemeApp)
          }
        }
      })
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
    },
    getSchemeApp () {
      this.schemeApp = this.$store.state.ExternalUserIrrigation.schemeApplicationLists.find(app => app.farmer_id === this.$store.state.Auth.authUser.user_id)
    },
    async checkUser () {
        this.loadMsg = true
        return await RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo + '?with_data=' + false).then(response => {
            this.loadMsg = false
            if (response.success) {
                this.overlay = false
                this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', response.data)
                return { loggedFarmerId: response.data.id }
            } else {
                return { loggedFarmerId: 0 }
            }
        })
    },
    async paymentStatusUpdate () {
      if (this.$route.params.status && this.$route.query.transId) {
          const status = this.$route.params.status
          const tranId = this.$route.query.transId
          await RestApi.getData(irriSchemeServiceBaseUrl, irriSchemePayment + status, { transId: tranId }).then(response => {
              if (response.success) {
                  this.$toast.success({
                  title: 'Success',
                  message: response.message,
                  color: '#D6E09B'
                  })
              } else {
                  this.$toast.error({
                  title: 'Error',
                  message: response.message
                  })
              }
          })
          this.$router.push('/irrigation/scheme-application')
      }
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('pump_install.pending')
      } else if (status === 2) {
        return this.$t('pump_install.processing')
      } else if (status === 3) {
        return this.$t('pump_install.selected')
      } else if (status === 4) {
        return this.$t('pump_install.review')
      } else if (status === 5) {
        return this.$t('pump_install.reject')
      } else if (status === 6) {
        return this.$t('pump_install.current_status')
      } else if (status === 7) {
        return this.$t('irrigation_task.verified')
      } else if (status === 8) {
        return this.$t('pump_install.agreement')
      } else if (status === 9) {
        return this.$t('pump_install.requisition')
      } else if (status === 10) {
        return this.$t('pump_install.supply')
      } else if (status === 11) {
        return this.$t('pump_install.installation')
      } else if (status === 12) {
        return this.$t('pump_install.participation_fee')
      } else if (status === 13) {
        return this.$t('pump_install.close')
      } else if (status === 14) {
        return this.$t('pump_install.installed')
      } else if (status === 15) {
        return this.$t('pump_install.Send')
      } else if (status === 16) {
        return this.$t('pump_install.forwarded_to_nothi')
      }
    }
  }
}
</script>
<style scoped>
    .stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media (max-width: 768px) {
        font-size: 12px;
    }
    }

    .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
    }

    .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
    }

    .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
    }

    .stepper-item.active {
    font-weight: bold;
    }

    .stepper-item.completed .step-counter {
    background-color: #4bb543;
    color:white;
    }

    .stepper-item.completed::after {
      position: absolute;
      content: "";
      border-bottom: 2px solid #4bb543;
      width: 100%;
      top: 20px;
      left: 50%;
      z-index: 3;
    }
    .stepper-item:first-child::before {
      content: none;
    }
    .stepper-item:last-child::after {
      content: none;
    }
    .step-name {
      color: black;
      font-weight: bold;
      font-size: 10px;
    }
</style>
