<template>
  <b-container fluid>
    <b-overlay :show="loadingForm">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
              <b-row >
                <b-col lg="12" sm="12">
                  <h5 class="mb-2" style="font-weight:bold"><i class="ri-list-check"></i> {{ $t('externalUserIrrigation.participation_Fee') + ' ' + $t('globalTrans.list') }}</h5>
                    <table class="table table-bordered table-sm">
                      <tr>
                        <th>{{ $t('irrigation_config.circle_area') }}</th>
                        <th>{{ $t('pump_install.amount') }}</th>
                        <th>{{ $t('externalUserIrrigation.payment_type') }}</th>
                        <th>{{ $t('externalUserIrrigation.payment_status') }}</th>
                        <th class="text-center">{{ $t('globalTrans.action') }}</th>
                      </tr>
                      <tr v-for="(pay, index) in paymentList" :key="index">
                        <td>{{ getCircleAreName(pay.circle_area_id) }}</td>
                        <td>{{ pay.amount }}</td>
                        <td>
                          <span v-if="pay.payment_type===1" class="badge badge-success">{{ $t('externalUserIrrigation.advance') }}</span>
                          <span v-else class="badge badge-warning">{{ $t('externalUserIrrigation.due') }}</span>
                        </td>
                        <td>
                          <span v-if="pay.payment_status===2" class="badge badge-success">{{ $t('externalUserIrrigation.paid') }}</span>
                          <span v-else class="badge badge-warning">{{ $t('externalUserIrrigation.unpaid') }}</span>
                        </td>
                        <td class="text-center">
                          <div v-if="pay.payment_status === 1">
                            <b-button class="btn btn-sm btn-success"
                            @click="schemePartPendingPayment(pay, 0)"
                            >
                                {{ $t('externalUserIrrigation.pay') }}
                            </b-button>
                            <b-button class="btn ml-1 btn-sm btn-warning"
                            @click="schemePartPendingPayment(pay, 1)"
                            >
                                {{ $t('externalUserIrrigation.bypass')}}
                            </b-button>
                          </div>
                        </td>
                      </tr>
                    </table>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { bmdaSchemeAppPartFeePayment, getSchemeApplicaionPartFeeBmda } from '../../api/routes'
export default {
  props: ['applicationId'],
  mounted () {
    core.index()
  },
  created () {
    this.getAmount()
  },
  data () {
    return {
      loadingForm: false,
      status: '',
      saveBtnName: this.$t('globalTrans.save'),
      totalAdvance: 0,
      paymentList: []
    }
  },
  methods: {
    async schemePartPendingPayment (pay, bypass) {
      this.loadingForm = true
      const payment = {
        amount: pay.amount,
        scheme_application_id: this.applicationId,
        circle_area_id: pay.circle_area_id,
        scheme_participation_fee_id: pay.id,
        is_bypass: bypass
      }
      let result = null
      result = await RestApi.postData(irriSchemeServiceBaseUrl, bmdaSchemeAppPartFeePayment, payment)
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: true })
      this.loadingForm = false
      if (result.success) {
        if (result.success === 2) {
          this.$toast.success({
            title: 'Success',
            message: result.message
          })
        } else {
          window.location = result.url
        }
        this.$bvModal.hide('modal-bmda-part-fee')
      } else {
        this.$toast.error({
          title: 'Error',
          message: result.message
        })
      }
    },
    getCircleAreName (id) {
      const singleCat = this.$store.state.ExternalUserIrrigation.circleAreaList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return singleCat.text_bn !== undefined ? singleCat.text_bn : ''
      } else {
        return singleCat.text_en !== undefined ? singleCat.text_en : ''
      }
    },
    async getAmount () {
      this.loadingForm = true
      const param = { scheme_application_id: this.applicationId }
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, getSchemeApplicaionPartFeeBmda, param)
      this.loadingForm = false
      if (result.success) {
        this.paymentList = result.data
      }
    }
  }
}
</script>
